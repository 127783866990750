import { AppState, Auth0Provider } from '@auth0/auth0-react'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { auth0LinkAccountContext, auth0LinkAccountRedirectKey } from './auth0-link-account'

export const Auth0LinkAccountProvider = ({ children }: { children: React.ReactNode }) => {
	const navigate = useNavigate()

	const redirect_uri = useMemo(() => {
		const search = new URLSearchParams(window.location.search)
		search.append(auth0LinkAccountRedirectKey, '')

		return `${window.location.origin}/registration?${search.toString()}`
	}, [])

	return (
		<Auth0Provider
			context={auth0LinkAccountContext}
			domain={import.meta.env.VITE_AUTH0_DOMAIN}
			clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
			onRedirectCallback={(appState: AppState | undefined) => {
				navigate(appState?.returnTo || window.location)
			}}
			useRefreshTokens={true}
			skipRedirectCallback={!window.location.href.includes(`${auth0LinkAccountRedirectKey}`)}
			authorizationParams={{
				redirect_uri,
				audience: import.meta.env.VITE_AUTH0_AUDIENCE,
			}}
		>
			{children}
		</Auth0Provider>
	)
}
