import { AppState, Auth0Provider } from '@auth0/auth0-react'
import { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

import { auth0LinkAccountRedirectKey } from '@/lib/auth0-link-account'

type Props = {
	children: ReactNode
}

// https://community.auth0.com/t/how-to-retrieve-refresh-token-using-auth0-auth0-react/69700/2
export default function Auth0ProviderWithNavigate({ children }: Props) {
	const navigate = useNavigate()
	const domain = import.meta.env.VITE_AUTH0_DOMAIN
	const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID
	const redirectUri = window.location.origin + import.meta.env.VITE_AUTH0_REDIRECT_URI
	const audience = import.meta.env.VITE_AUTH0_AUDIENCE
	function onRedirectCallback(appState: AppState | undefined) {
		const search = new URLSearchParams()
		if (appState?.returnTo) {
			search.set('returnTo', decodeURIComponent(appState.returnTo))
		}
		if (appState?.user) {
			search.set('user', appState.user)
		}

		navigate({
			pathname: window.location.pathname,
			search: search.size > 0 ? `?${search.toString()}` : undefined,
		})
	}
	if (!(domain && clientId && redirectUri && audience)) {
		throw new Error('Missing required environment variables')
	}

	return (
		<Auth0Provider
			domain={domain}
			clientId={clientId}
			onRedirectCallback={onRedirectCallback}
			useRefreshTokens={true}
			skipRedirectCallback={window.location.href.includes(auth0LinkAccountRedirectKey)}
			cacheLocation="localstorage"
			authorizationParams={{
				redirect_uri: redirectUri,
				audience: audience,
				scope: 'openid profile email phone offline_access permissions',
			}}
		>
			{children}
		</Auth0Provider>
	)
}
