import * as React from 'react'
import { useSearchParams } from 'react-router-dom'

import { UserShapeSchema } from '@/utils/validations'

export function useUserFromSearchParams() {
	const [searchParams] = useSearchParams()

	const userParam = searchParams.get('user')

	return React.useMemo(() => {
		if (!userParam) {
			return null
		}
		const user = UserShapeSchema.deepPartial().safeParse(JSON.parse(userParam))
		return user.data
	}, [userParam])
}
