import { withAuthenticationRequired } from '@auth0/auth0-react'
import { ComponentType } from 'react'

import { useUserFromSearchParams } from '@/hooks/use-user-from-search-params.ts'
import { MyPermissions } from '../constants/my-permissions.ts'
import { MyRole } from '../constants/my-role.ts'
import { AuthorizationGuard } from './authorization-guard.tsx'
import { Loading } from './loading/index.ts'

type Props = {
	component: ComponentType
	requiredRoles?: MyRole[]
	requiredPermissions?: MyPermissions[]
}
export default function AuthenticationGuard({ component, requiredRoles, requiredPermissions }: Props) {
	const user = useUserFromSearchParams()

	const Component = withAuthenticationRequired(AuthorizationGuard, {
		returnTo: () => encodeURIComponent(window.location.pathname + window.location.search),
		onRedirecting: () => <Loading />,
		loginOptions: {
			appState: user ? { user: JSON.stringify(user) } : undefined,
			authorizationParams: {
				connection: requiredRoles?.includes(MyRole.Consumer) ? 'sms' : undefined,
				login_hint: requiredRoles?.includes(MyRole.Consumer) ? user?.phone_number : undefined,
			},
		},
	})
	return (
		<Component
			requiredRoles={requiredRoles}
			requiredPermissions={requiredPermissions}
			Component={component}
		/>
	)
}
