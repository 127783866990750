import { useQuery } from '@tanstack/react-query'
import { HTTPError, KyInstance } from 'ky'

import { Nullable } from '../../@types/nullable'
import { usePaymentService } from '../services/providers/payment-service-provider.tsx'
import { useAccounts } from './use-accounts.ts'

export type PurchasePowerResponse = {
	account: {
		account_type: string
		customer_type: string
		id: number
		is_primary: boolean
		last_four: string
		status: string
		syn_account_id: string
		user_id: number
		vendor: string
		vendor_institution_id: string
		vendor_institution_name: string
		vendor_public_token: string
		vendor_type: string
		verified: boolean
	}
	purchase_power: number
}

async function getPurchasePower(httpInstance: KyInstance) {
	try {
		const response = await httpInstance.get('external_accounts/purchase_power')
		return await response.json<PurchasePowerResponse>()
	} catch (error) {
		if (error instanceof HTTPError) {
			const json = await error.response.json()
			if (json.message === 'ITEM_LOGIN_REQUIRED') {
				error.message = json.message
			}
		}
		throw error
	}
}

export function usePurchasePower() {
	const { paymentServiceClient, isLoading: serviceLoading, isAuthenticated } = usePaymentService()
	const accounts = useAccounts()

	const getLastSuccessfulValue = (): Nullable<PurchasePowerResponse> => {
		try {
			const storedValue = localStorage.getItem('last_purchase_power')
			return storedValue ? JSON.parse(storedValue) : undefined
		} catch (_) {
			return null
		}
	}

	const result = useQuery<Nullable<PurchasePowerResponse>, HTTPError, Nullable<PurchasePowerResponse>>({
		queryKey: ['purchase_power'],
		queryFn: () => getPurchasePower(paymentServiceClient.httpInstance),
		enabled: !serviceLoading && isAuthenticated && !accounts.isLoading && !!accounts.data?.length,
		retry: (failureCount, error) => {
			if (error.message === 'ITEM_LOGIN_REQUIRED') {
				return false
			}
			return failureCount < 2
		},
		staleTime: 600000,
	})

	if (result.data && !result.isLoading && !result.isError) {
		localStorage.setItem('last_purchase_power', JSON.stringify(result.data))
	}

	return {
		...result,
		isLoading: serviceLoading || result.isLoading || result.isFetching,
		data: result.data || getLastSuccessfulValue() || null,
	}
}
