export const LoadingIcon = () => {
	return (
		<svg
			width="10"
			height="10"
		>
			<circle
				cx="10"
				cy="10"
				r="38"
				fill="none"
				stroke="#BBC8D4"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<circle
				cx="10"
				cy="10"
				r="38"
				fill="none"
				stroke="#244D74"
				strokeWidth="2"
				strokeLinecap="round"
				strokeDasharray="30"
				strokeDashoffset="20"
				className="origin-center animate-spin"
			/>
		</svg>
	)
}
