import { Landmark, Loader } from 'lucide-react'

import { Nullable } from '@/@types/nullable'
import { PurchasePowerResponse } from '@/api/hooks/use-purchase-power.ts'
import { Card } from '@/components/ui'
import { formatCentsToCurrency } from '@/utils/money-utils.ts'
import { SpendingLimitInfo } from './spending-limit-info.tsx'

export type PurchasePowerProps = {
	purchasePower: Nullable<PurchasePowerResponse>
	isLoading?: boolean
}

export function PurchasePower({ purchasePower, isLoading }: PurchasePowerProps) {
	return (
		<Card
			data-testid="purchase-power"
			className="flex flex-col gap-12 rounded-lg border-0 bg-blue-linear p-6 text-white shadow-lg"
		>
			<div
				className="flex items-center gap-2"
				data-testid="bank-details"
			>
				<Landmark />
			</div>
			<div
				className="flex items-end justify-between text-3xl"
				data-testid="bank-balance"
			>
				<div className="flex flex-col gap-1">
					<SpendingLimitInfo />
					<div className="typography-display-small flex items-center">
						<span className={`${isLoading ? 'animate-pulse' : ''}`}>
							{formatCentsToCurrency(purchasePower?.purchase_power)}
						</span>
						<span className={'ml-2 mt-1'}>
							{isLoading && (
								<Loader
									style={{ animation: 'spin 3s linear infinite' }}
									size={20}
								/>
							)}
						</span>
					</div>
				</div>
				<div className="typography-label-large">
					{purchasePower?.account?.last_four && <span>#{purchasePower?.account?.last_four}</span>}
				</div>
			</div>
		</Card>
	)
}
