import dayjs from 'dayjs'
import { z } from 'zod'

export const AddressSchema = z.object({
	street_one: z.string().min(1, { message: 'Street required' }),
	street_two: z.string().optional(),
	city: z.string().min(1, { message: 'City required' }),
	state: z.string().length(2, { message: 'State required' }),
	postal_code: z
		.string()
		.min(1, { message: 'Postal code required' })
		.length(5, { message: 'Postal code must be exactly 5 digits' }),
})

export const AddressShapeSchema = z.object({
	street_one: z.coerce.string(),
	street_two: z.coerce.string(),
	city: z.coerce.string(),
	state: z.coerce.string(),
	postal_code: z.coerce.string(),
})

export const UserSchema = z.object({
	email: z.string().email({ message: 'Invalid email address' }),
	first_name: z.string().min(2, { message: 'First name is required' }),
	last_name: z.string().min(2, { message: 'Last name is required' }),
	date_of_birth: z
		.string()
		.min(10, { message: 'Birthday is required' })
		.refine((value) => dayjs(value, 'MM/DD/YYYY', true).isValid(), {
			message: 'Invalid date',
		})
		.refine((value) => dayjs().diff(dayjs(value, 'MM/DD/YYYY'), 'year') >= 18, {
			message: 'You must be at least 18 years old.',
		}),
	phone_number: z
		.string()
		.min(5, { message: 'Phone number required' })
		.refine(
			(data) => {
				return data.replace(/[^\d]/g, '').length === 10
			},
			{
				message: 'Phone number invalid',
			},
		),
	address: AddressSchema,
	terms_accepted: z
		.boolean({ coerce: true })
		.refine((value) => value === true, { message: 'You must accept the terms' }),
})

export const UserShapeSchema = z.object({
	email: z.coerce.string(),
	first_name: z.coerce.string(),
	last_name: z.coerce.string(),
	date_of_birth: z.coerce.string(),
	phone_number: z.coerce.string(),
	address: AddressShapeSchema,
})

export const isNumeric = (value: string) => {
	return /^-?\d+$/.test(value)
}
